import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'

const Kostnadsbesparelser = props => {
  const { instance, lang } = props.pageContext
  const title = `Kostnadsbesparelse | Administrative kostnader | ${
    instance.title
  }`
  const description =
    'Ved å se på administrative kostnader, kan du oppnå høye kostnadsbesparelser. Cost Partner hjelper deg!'

  return (
    <Layout {...props}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
      <div className="main-content content-module">
        <Container textWidth>
          <h1>Kostnadsbesparelser og administrative kostnader</h1>
          <p>
            <strong>
              Ønsker du å oppnå høyere kostnadsbesparelser i virksomheten din?
              Ved å se på administrative kostnader kan man som oftest finne
              områder der det kan være økonomisk smart å reorganisere,
              reforhandle eller redusere.
            </strong>
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>
          <p>
            La oss i Cost Partner hjelpe deg med dette. Ta kontakt for en
            uforpliktende samtale.
          </p>

          <h2>Hvordan oppnå kostnadsbesparelser på administrative kostnader</h2>
          <p>
            Ved å ha en strategisk kostnadsstyring og økt fokus på
            kostnadsbesparelse, kan din virksomhet oppleve en økonomisk
            lønnsomhet som kan påvirke bedriften positivt. Her kan man blant
            annet se på administrative kostnader, og finne ut av hvor man kan
            spare mest.
          </p>
          <p>
            Dette kan være alt fra å se på hvordan man effektiviserer,
            digitaliserer og reduserer der det er mulig. Cost Partner har god
            erfaring med nettopp dette, og vi ser at våre kunder oppnår en
            <Link to={`/${instance.id}/${lang}/about/`}>
              {' '}
              gjennomsnittlig besparelse på opptil 30%
            </Link>
            .
          </p>

          <h2>Hva er kostnader tilknyttet det administrative?</h2>
          <p>
            Kostnader knyttet til det administrative kan{' '}
            <a
              href="https://estudie.no/hvordan-redusere-kostnadene/"
              target="_blank"
              rel="noopener noreferrer"
            >
              gi indirekte eller direkte fordeler eller rimeligere ytelser
            </a>{' '}
            - men her kan det være økonomisk smart å være klar over hva dette
            innebærer.
          </p>
          <p>
            Ved å ta en grundig gjennomgang og analyse av alle bedriftens
            administrative kostnader, kan du få rede på hva virksomheten kan
            gjøre for å oppnå kostnadsbesparelse.
          </p>
          <p>
            Slike kostnader inkluderer de administrative aktivitetene,
            omkostninger, og andre områder. Velger du Cost Partner som din
            partner, kan vi se på totale kostnader og den samlede administrative
            kostnaden.
          </p>

          <h2>Kostnadskategorier vi fokuserer på</h2>
          <p>Vi utfører blant annet analyser innen kostnadskategorier som:</p>
          <ul>
            <li>
              Facility services: Renhold, kantinedrift, drikkeautomater,
              renovasjon, sikkerhet, energi, serviceavtaler
            </li>
            <li>
              Forbruksmateriell: Kontorrekvisita, datarekvisita,
              driftsrekvisita, trykksaker, emballasje, arbeidstøy- og vern,
              industrirekvisita
            </li>
            <li>Tele og IT: Telefon, kontormaskiner</li>
            <li>Transport: Bil, tog, sjøfrakt, flyfrakt</li>
            <li>Annet: Billeasing, banktjenester</li>
          </ul>

          <h2>Hvem er Cost Partner Norge AS?</h2>
          <p>
            Cost Partner er et konsulentselskap innen kostnadsbesparelser. Vi
            bistår våre kunder innen en rekke kostnadskategorier. Vi tar ikke
            honorar dersom vi ikke finner noen kostnadsbesparelser - dette
            kaller vi “no cure, no pay”. Det er viktig for oss som din partner
            at vi lykkes i det vi gjør.
          </p>
          <p>
            En omfattende analyseprosess og vår unike database med bl.a.
            produkter, priser og leverandørinformasjon, samt et betydelig
            innkjøpsvolum, gir besparelser som selskaper ikke kan oppnå på egen
            hånd.
          </p>
          <h3>Tilleggseffekter</h3>
          <p>
            Ved å ansette oss som din partner i kostnadsbesparelse vil du ikke
            bare spare penger, men få tilleggseffekter som kompetanseoverføring,
            reduksjon av antall leverandører, reduksjon av fakturamende, økt
            kjøpslojalitet til valgt leverandør, forbedrede innkjøpsrutiner og
            optimalisering av produkter, kvalitet og service.
          </p>
          <h3>Fornøyde kunder</h3>
          <p>
            Vi har en lang liste av fornøyde kunder, blant annet har vi hjulpet
            store norske selskap som Mester Grønn, Home & Cottage, Vitusapotek,
            Nokas, DNB, Astrup Fearnley, Power, Nikita Hair og XXL med å få ned
            sine kostnader. For eksempel bistod vi Kid Interiør i å redusere
            sine kostnader på facility services med 40%, uten å belaste deres
            administrasjon.
          </p>

          <h2>Cost Partners 8-trinns prosess</h2>
          <p>
            Vi jobber ut i fra en 8-trinns prosess som starter med at vi inngår
            en samarbeidsavtale med deg og ditt selskap, deretter begynner vi
            innsamling av data, både om vår nye samarbeidspartner og om
            markedet, der vi blant annet jobber med å kartlegge de
            administrative kostnadene dere måtte ha, samt innenfor andre felt,
            nevnt over.
          </p>
          <p>
            Vi gjør deretter en full kostnadsanalyse for å se hvor dere kan
            spare penger før vi presenterer dette for dere i et analysemøte.
            Disse første fire trinnene gjør vi fullstendig uforpliktende.
          </p>
          <p>
            Om dere liker det dere hører og ønsker å gå videre med oss som
            partnere, begynner vi en anbudsprosess.. Her bestemmes det hvilke
            leverandører som skal inviteres, det settes visse
            kravspesifikasjoner og snakkes om hvilken minstebesparelse som må
            oppnås. Heretter kommer trinn nr. 6 der vi presenterer en
            resultatrapport. Om denne godkjennes kan implementeringen av de nye
            avtalene begynne.
          </p>

          <h3>No cure, no pay</h3>
          <p>
            Vårt siste trinn er å kjøre etterkontroller. På denne måten kan vi
            få oversikt over hvordan de nye avtalene har fungert for deres
            firma, hvor mye dere har spart og om det er andre, mer gunstige
            avtaler der ute som heller bør bli tatt i bruk. Det er også ut i fra
            disse kontrollene vi finner ut hva vår provisjon blir. Vi tar altså
            ikke betalt før vi vet at arbeidet vi har gjort har ført til
            vesentlige kostnadsbesparelser for våre kunder.
          </p>
          <p>
            Vår “No cure, no pay” policy er altså 100 prosent resultatbasert. Vi
            krever ingen forhåndsbetaling, men tar første halvdel av vårt
            honorar etter resultatrapporteringen. Vårt fulle honorar er 50
            prosent av kun første års faktiske besparelse - denne revideres
            hvert halvår.
          </p>

          <h2>Ta kontakt med oss i dag</h2>
          <p>
            Cost Partner AS er din partner i kostnadsbesparelse og
            avtaleforhandling. Vi er et konsulentselskap som jobber med
            besparelser på alle indirekte og administrative kostnader.
          </p>
          <p>
            Ønsker du å vite mer om Cost Partner og om hva vi kan tilby deg? Ta
            gjerne kontakt med oss allerede i dag. Vi tar gjerne en
            uforpliktende samtale om hvordan vi kan hjelpe din bedrift.
          </p>
          <p>
            <Link to={`/${instance.id}/${lang}/contact/`}>Ta kontakt</Link>
          </p>
        </Container>
      </div>
    </Layout>
  )
}

export default Kostnadsbesparelser
